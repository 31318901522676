import React from 'react'
import { Hero } from '../../components/HeroSection/Hero'
import { NavBar } from '../../components/NavBar/NavBar'
import { HowWeWork } from '../../components/HowWeWork/HowWeWork'
import { Rtms } from '../../components/Rtms/Rtms'
import { Products } from '../../components/Products/Products'
import { ContactUs } from '../../components/ContactUs/ContactUs'
import { Footer } from '../../components/Footer/Footer'

import './HomePageStyles.css'

const HomePage = () => {
  return (
    <div className="pageContainer">
      <NavBar/>
      <Hero/>
      <HowWeWork/> 
      <Rtms/>
      <Products/>
      <ContactUs/>
      <Footer/>   
   
    </div>
  )
}

export default HomePage