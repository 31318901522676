import React from 'react'
import './HeroStyles.css'
import heroimage from '../../assets/heroimage.jpg'

export const Hero = () => {
  return (
    <div className='Hcontainer'>
        
        <div className="description">
            <div className="descriptionHeading">
            The future of healthcare is in your hands.
            </div>
            <p className="descriptionParagraph">
            Healthcare providers can deliver the best care when they have powerful, intuitive tools. Our technology helps them work effectively within hospitals, connect remotely with patients, and conduct groundbreaking medical research. The result is care that becomes more efficient, more personalized, and ultimately more human.
            </p>
            <div className="CTA">
            <a href="/Contact" className="heroa">Schedule a Demo</a>
            </div>
        </div>
        <img src={heroimage} alt="" className="heroImage" />
    </div>
  )
}
