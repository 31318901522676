import React from 'react'
import './RtmsStyles.css'
// import heroimage from '../../assets/heroimage.jpg'

export const Rtms = () => {
  return (
    <div className='Hcontainer'>
        
        <div className="Rtmsdescription">
            <div className="subHeading">THE FUTURE OF MEDICINE</div>
            <div className="RtmsHeading">
            Real Time Patient Monitoring Systems
            </div>
            <p className="RtmsParagraph">
            When we say patient monitoring system, we mean any set of technology and/or processes used to by healthcare providers to monitor key biological indicators. An electrocardiography (“ECG”) machine that enables physicians to monitor the vital signs of the heart is an example of a patient monitoring system.
            </p>
            <div className="RtmsCTA">
            <a href="/products" className="Rtmsa">Learn More</a>
            </div>
        </div>
        {/* <img src={heroimage} alt="" className="heroImage" /> */}
    </div>
  )
}
