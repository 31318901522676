import React from 'react'
import './ProductPageTitleStyles.css'
import his from '../../assets/his.png'
import pms from '../../assets/pms.png'
import pacs from '../../assets/pacs.png'
import rtms from '../../assets/rtms.png'



export const ProductPageTitle = () => {
  return (
    <div className='productHcontainer'>
        <div className="pRtmsdescription">
            <div className="subHeading">Our Solutions</div>
            <div className="RtmsHeading">Keeping Hospital Requirement Ahead</div>
            <p className="RtmsParagraph">We’ve worked closely with multiple health workers and others in the healthcare community to make it easy for you to add more features to Our solutions.</p>
        </div>

        <div className="productcontainer">

          <div className="SingleProduct">
            <img className="ProductImage" src={his} alt="" />
            <div className="ProductCTA"><a href="/his" className="Rtmsa"> View Details </a></div>
          </div>

          <div className="SingleProduct">
            <img className="ProductImage" src={rtms} alt="" />
            <div className="ProductCTA"><a href="" className="Rtmsa"> View Details </a></div>
          </div>

          <div className="SingleProduct">
            <img className="ProductImage" src={pms} alt="" />
            <div className="ProductCTA"><a href="" className="Rtmsa"> View Details </a></div>
          </div>

          <div className="SingleProduct">
            <img className="ProductImage" src={pacs} alt="" />
            <div className="ProductCTA"><a href="" className="Rtmsa"> View Details </a></div>
          </div>

        </div>
    </div>
  )
}
