import React from 'react'
import './NavBarStyles.css'
import logo from '../../assets/Logo.svg'

export const NavBar = () => {
  return (
    <div className='container'>
               
        <div className="menuElements">   
            <a href="/#"><img src={logo} alt="" className="logo" /></a>
            <label className="menulabel" for="toggle">&#9776;</label>
            <input type="checkbox" id="toggle"/>
            
            <div className="menu">
                    <a className="menuli" href="/about"> ABOUT </a>
                    <a className="menuli" href="/products"> PRODUCTS </a>
                    <a className="menuli" href="/Contact"> CONTACT US </a>
            </div>
           
        </div>

        <div className="divider">
        </div>

    </div>
  )
}
