import React from 'react'
import './ContactPageTitleStyles.css'
import his from '../../assets/his.png'
import pms from '../../assets/pms.png'
import pacs from '../../assets/pacs.png'
import rtms from '../../assets/rtms.png'



export const ContactPageTitle = () => {
  return (
    <div className='contactHcontainer'>
        <div className="pRtmsdescription">
            <div className="subHeading">Contact Us</div>
            <div className="RtmsHeading">We'd love to hear from you.</div>
            <p className="contactRtmsParagraph">Whether you are curious about features, demo, a trial or even press. we are ready to answer any and all questions.</p>
        </div>
    </div>
  )
}
