import React from 'react'
import './HowWeWorkStyles.css'
import rocket from '../../assets/Rocket.svg'
import notepad from '../../assets/notepad.svg'
import monitor from '../../assets/monitor.svg'
import secure from '../../assets/secure.svg'



export const HowWeWork = () => {
  return (
    <div className='HWWcontainer'>
        
        <div className="description2">
            <div className="descriptionHeading2">
            Keeping patients and healthcare providers connected, in one system.
            </div>
            <p className="descriptionParagraph2">
            We’ve worked closely with multiple health workers and others in the healthcare community to make it easy for you to add more features to Our solutions. Choose gunasys because we work considering the following things:
            </p>
        </div>
        <div className="featuresContainer">

            <div className="Feature">
              <img src={rocket} alt="" className="featureIcon" />
              
              <div className="FeatureTitle">
              Innovation
              </div>
              <div className="featureDescription">
              Next Generation Technology To Automate Workflow, Elevate Clinical, Financial, & Operational Outcomes.
              </div>
            </div>


            <div className="Feature">
              <img src={notepad} alt="" className="featureIcon" />
              
              <div className="FeatureTitle">
              Collect More Data
              </div>
              <div className="featureDescription">
              G-HIS will enable you to gain a competitive edge by employing AI/ML approaches to collect data.
              </div>
            </div>


            <div className="Feature">
              <img src={monitor} alt="" className="featureIcon" />
              
              <div className="FeatureTitle">
              Maintain Regulation
              </div> 
              <div className="featureDescription">
              Built On A Platform staff Are Already Familiar With & Compliant With Your Regulations.
              </div>
            </div>


            <div className="Feature">
              <img src={secure} alt="" className="featureIcon" />
              
              <div className="FeatureTitle">
              Stay Organized
              </div>
              <div className="featureDescription">
              We design products to protect your privacy and give you control over your information.
              </div>
            </div>


        </div>
    </div>
  )
}
