import React from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { Footer } from '../../components/Footer/Footer'
import {AboutTitle} from '../../components/AboutTitle/AboutTitle'
import { HowWeWork } from '../../components/HowWeWork/HowWeWork'
import { ContactUs } from '../../components/ContactUs/ContactUs'



import './AboutStyles.css'

const About = () => {
  return (
    <div className="pageContainer">
      <NavBar/>
      <AboutTitle/>
      <HowWeWork/> 
      <ContactUs/>
      <Footer/>   
   
    </div>
  )
}

export default About