import React from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { Footer } from '../../components/Footer/Footer'
import { HisContent } from '../../components/HisContent/HisContent'
import { ContactUs } from '../../components/ContactUs/ContactUs'





import './HisPageStyles.css'

const HisPage = () => {
  return (
    <div className="pageContainer">
      <NavBar/>
      <HisContent/>
      <ContactUs/>
      <Footer/>   
    </div>
  )
}

export default HisPage