import logo from './logo.svg';
import './App.css';
import HomePage from './pages/HomePage/HomePage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProductsPage from './pages/ProductsPage/ProductsPage';
import About from './pages/About/About';
import ContactUSPage from './pages/ContactUSPage/ContactUSPage';
import HisPage from './pages/HisPage/HisPage';

function App() {
  return (
    <BrowserRouter>
    <Routes>
     <Route exact path='/' element ={<HomePage/>}> </Route>
     <Route exact path = '/about' element={<About/>}></Route>
     <Route exact path = '/products' element={<ProductsPage/>}></Route>
     <Route exact path = '/Contact' element={<ContactUSPage/>}></Route>
     <Route exact path = '/his' element={<HisPage/>}></Route>


    </Routes>
    </BrowserRouter>
    
  );
}

export default App;
