import React from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { Footer } from '../../components/Footer/Footer'
import { ContactPageTitle } from '../../components/ContactPageTitle/ContactPageTitle'
import { ContactUs } from '../../components/ContactUs/ContactUs'



import './ContactUSPageStyles.css'

const ContactUSPage = () => {
  return (
    <div className="pageContainer">
      <NavBar/>
      <ContactPageTitle/>
      <ContactUs/>
      <Footer/>   
    </div>
  )
}

export default ContactUSPage