import React from 'react'
import './AboutTitleStyles.css'
// import heroimage from '../../assets/heroimage.jpg'

export const AboutTitle = () => {
  return (
    <div className='aboutHcontainer'>
        <div className="Rtmsdescription">
            <div className="subHeading">About Our Company</div>
            <div className="RtmsHeading">
            Gunasys Tech Pvt. Ltd.
            </div>
            <p className="RtmsParagraph">
            Gunasys Tech Pvt. Ltd. is a digital transformation consultancy & software development company that provides cutting edge engineering solutions in healthcare IT since 2017. Delivering digital transformation at scale, our product facilitates secure, real-time data collection, analysis and forecasting and includes tools to effectively manage patient flow, demand and capacity, discharge optimization, whole-system visibility, patient activity and operational processes, all at a price that is affordable.
            We aim to provide meaningful, useful products and services that meet the needs of our healthcare clients in a way that is easy to interpret and understand for any level of user.
            </p>
            {/* <div className="RtmsCTA"> */}
            {/* <a href="" className="Rtmsa">Learn More</a> */}
            {/* </div> */}
        </div>
        {/* <img src={heroimage} alt="" className="heroImage" /> */}
    </div>
  )
}
