import React from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { Footer } from '../../components/Footer/Footer'
import { ProductPageTitle } from '../../components/ProductPageTitle/ProductPageTitle'
import { ContactUs } from '../../components/ContactUs/ContactUs'



import './ProductsPageStyles.css'

const ProductsPage = () => {
  return (
    <div className="pageContainer">
      <NavBar/>
      <ProductPageTitle/>
      <ContactUs/>
      <Footer/>   
    </div>
  )
}

export default ProductsPage