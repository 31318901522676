import React from 'react'
import './ContactUsStyles.css'
import contactimage from '../../assets/contact.png'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
 
export const ContactUs = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_wen4kbc', 'template_19u5qdw', form.current, 'DpGAsEtvY2zVpFDew')
      .then((result) => {
          // console.log(result.text);
          alert('message sent successfully');
      }, (error) => {
          console.log(error.text);
      });
    e.target.reser();
  };

  return (
    <div className='ContactContainer'>

        <div className="FormContainer">
              <div className="InputTitle">
                Get in Touch
              </div>

              <form ref={form} onSubmit={sendEmail} >
                <div className="inputbox">
                  <input type="text" id="username" name="username" placeholder='Enter Your Name' required/>
                  <label><span> Name</span></label>
                </div>

                <div className="inputbox">
                  <input type="email"  id="email" name="email" placeholder='Enter Your Email Address' required />
                  <label><span> Email Address</span></label>
                </div>

                <div className="inputbox">
                  <input type="text" id="company" name="company" placeholder='Enter Your Company Name' required/>
                  <label><span> Company Name</span></label>
                </div>

                <div className="inputbox">
                  <input type="text" id="message" name="message" placeholder='Enter Your Message Here' required />
                  <label><span> Your Message</span></label>
                </div>

                <div className="noticeText">
                Call us at 01-4265755 or +977-9845245563
                </div>

                <input className="InputSubmitBtn" type="submit"  value="Send Message" />

              </form>        
        </div>
        
              <img src={contactimage} alt="" className="contactImage" />
    </div>
  )
}
