import React from 'react'
import './HisContentStyles.css'
import his from '../../assets/his.png'
import pms from '../../assets/pms.png'
import pacs from '../../assets/pacs.png'
import rtms from '../../assets/rtms.png'
import hislist from '../../assets/HISlist.svg'



export const HisContent = () => {
  return (
    <div className='Hiscontainer'>
        <div className="pRtmsdescription">
            <div className="subHeading">The Future of Medicine</div>
            <div className="RtmsHeading"> Hospital Information System</div>
            <p className="contactRtmsParagraph">Hospital Information System (HIS) plays an important role in simplifying the workflow of hospitals by digitizing the entire operations of a hospital. Hospital information system has mainly two parts such as Clinical Modules and Administration modules.</p>
        </div>  
        <img className="hisImage" src={hislist} alt="" srcset="" />
        <p className="HISdescription">The Clinical module of this system is patient centric that deals with patient appointments, registration, billing, medicines, doctors and the treatments, surgeries. The clinical part eases out the tasks for consultants thus doctors spends more time with patients and able to consult more patients as well. </p>
        <p className="HISdescription">Whereas the Administration module of hospital information system (HIS) handles the back office information such as Accounts, Stores, Asset management, Human resource management, Corporate Billing, Insurance billing and so on. This is an important module for the hospital management to keep track of the revenue, outstanding payments, purchases and stocks.</p>
        <p className="HISdescription">The success of a hospital information system lies mainly on user-friendliness. And that is possible only if the system development team consists of health care professionals, a company in the healthcare domain, has close connection with every type of staff in a hospital so that they can understand their ability.</p>
        <p className="HISdescription">The overall advantages of the hospital information system (HIS) are: Erroneous is greatly reduced, Customer (Patient) satisfaction increases and thus foot fall increases, Eco Friendly green system implemented by reports/ results / information are moved digitally than in a paper, dashboards help the hospital management to a greater extent.</p>
    </div>
  )
}
