import React from 'react'
import './FooterStyles.css'
import Flogo from '../../assets/Logo.svg'

export const Footer = () => {
  return (
    <div className='FooterContainer'>

      <div className="FooterCollection">
         
        <div className="FintroCollection">
            <img src={Flogo} alt="" className="logo" />
            <div className="Fintro">
            Our products transform the way doctors and nurses work with their patients. Help Medical staff to ensure better patient safety while administering medication.
            </div>
        </div>

        <ul className="Fmenu">
            <li className="FmenuTitle">Our Products</li>
            <li ><a href="/his">HIS Solution</a></li>
            <li>HIS PACS Integration</li>
            <li>Patient Monitoring System</li>
        </ul>

        <ul className="Fmenu">
            <li className="FmenuTitle">Useful Links</li>
            <li><a href="/about"> About </a></li>
            <li><a href="/join"> Careers</a></li>
            <li>Request Demo</li>
        </ul>

        <ul className="Fmenu">

            <li className="FmenuTitle">Espoo, Finland</li>
            <li>Puistomäki</li>
            <li>Phone: <a href="tel:+358405070220">+358405070220</a></li>
            <br/>
            <li className="FmenuTitle">Bagmati, Nepal</li>
            <li>Putalisadak Road, Kathmandu</li>
            <li>Phone: <a href="tel:01426575">01426575</a>, <a href="tel:+9779845245563">+9779845245563</a></li>
            
        </ul>

      </div>

      <div className="Fdivider">

      </div>

      <div className="FooterCopyright">
        Copyright 2022 Gunasys Tech. All Rights Reserved.
      </div>

    </div>
  )
}
